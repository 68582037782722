import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// material-ui
import { Box, List, Typography } from "@mui/material";

// project import
import NavItem from "./NavItem";
import NavCollapse from "./NavCollapse";

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const navCollapse = item.children?.map((menuItem) => {
    if (menuItem?.showMenu) {
      switch (menuItem.type) {
        case "collapse":
          // If type is 'collapse', render NavCollapse component
          return <NavCollapse key={menuItem.id} menu={menuItem} level={1} />;
        case "item":
          // If type is 'item', render NavItem component
          return <NavItem key={menuItem.id} item={menuItem} level={1} />;
        default:
          // If type is neither 'collapse' nor 'item', render a Typography component with an error message
          return (
            <Typography
              key={menuItem.id}
              variant="h6"
              color="error"
              align="center"
            >
              Fix - Group Collapse or Items
            </Typography>
          );
      }
    }
  });

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {item.title}
            </Typography>
            {/* only available in paid version */}
          </Box>
        )
      }
      sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {navCollapse}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
