import { createSlice } from "@reduxjs/toolkit";

const subAssetAdditionSlice = createSlice({
  name: "subAssetAddition",
  initialState: false,
  reducers: {
    setsubAssetAddition: (state, action) => {
      return action.payload;
    },
  },
});

export const { setsubAssetAddition } = subAssetAdditionSlice.actions;
export default subAssetAdditionSlice.reducer;
