import { createSlice } from "@reduxjs/toolkit";

const copy_from_machineSlice = createSlice({
  name: "copy_from_machine",
  initialState: {
    copy_asset_id: "",
    copy_switch: false,
  },
  reducers: {
    set_copy_from_machine: (state, action) => {
      return action.payload;
    },
  },
});

export const { set_copy_from_machine } = copy_from_machineSlice.actions;
export default copy_from_machineSlice.reducer;
