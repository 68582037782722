import { createSlice } from "@reduxjs/toolkit";

const workflowActionListSlice = createSlice({
  name: "workflowActionList",
  initialState: [],
  reducers: {
    set_workflowActionList: (state, action) => {
      return action.payload;
    },
  },
});

export const { set_workflowActionList } = workflowActionListSlice.actions;
export default workflowActionListSlice.reducer;
