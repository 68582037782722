import { createSlice } from "@reduxjs/toolkit";

const main_assetSlice = createSlice({
  name: "main_asset",
  initialState: {
    selected_asset_id: "",
    selected_asset_name: "",
  },
  reducers: {
    set_main_asset: (state, action) => {
      return action.payload;
    },
  },
});

export const { set_main_asset } = main_assetSlice.actions;
export default main_assetSlice.reducer;
