import { createSlice } from "@reduxjs/toolkit";

const machine_edit_top_Slice = createSlice({
  name: "machine_edit_top",
  initialState: false,
  reducers: {
    set_machine_edit_top: (state, action) => {
      return action.payload;
    },
  },
});

export const { set_machine_edit_top } = machine_edit_top_Slice.actions;
export default machine_edit_top_Slice.reducer;
