import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DashboardOutlined,
  CasinoOutlined,
  ConfirmationNumberOutlined,
  CategoryOutlined,
  PersonOutline,
  AssignmentTurnedInOutlined,
  Assignment,
  SchemaOutlined,
  PersonOutlineOutlined,
  SettingsInputSvideoOutlined,
  PermDataSetting,
  SettingsOutlined,
  FmdGoodOutlined,
  Groups,
  Tune,
  SupervisorAccount,
  HowToReg,
  ManageAccounts,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
  ViewQuiltOutlined,
  TimelineOutlined,
  Checklist,
  FactoryOutlined,
} from "@mui/icons-material";

import {
  // DashboardOutlined,
  ChromeOutlined,
  QuestionOutlined,
  FileTextOutlined,
  AuditOutlined,
  ControlOutlined,
} from "@ant-design/icons";

const icons = {
  DashboardOutlined,
  CasinoOutlined,
  ConfirmationNumberOutlined,
  CategoryOutlined,
  PersonOutline,
  AssignmentTurnedInOutlined,
  Assignment,
  SchemaOutlined,
  PersonOutlineOutlined,
  SettingsInputSvideoOutlined,
  PermDataSetting,
  SettingsOutlined,
  FmdGoodOutlined,
  Groups,
  Tune,
  SupervisorAccount,
  HowToReg,
  ManageAccounts,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
  ViewQuiltOutlined,
  TimelineOutlined,
  Checklist,
  FactoryOutlined,
  ControlOutlined,
  QuestionOutlined,
  ChromeOutlined,
  FileTextOutlined,
  AuditOutlined,
};

// ==============================|| MENU ITEMS ||============================== //

function Dashboard() {
  const [access_list_data, setaccess_list_data] = useState([]);
  const [refresh, set_refresh] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const storedData = localStorage.getItem("access_list_data");
    if (storedData) {
      setaccess_list_data(JSON.parse(storedData));
    } else {
      set_refresh(!refresh);
    }
  }, [refresh]);

  return [
    {
      id: "group-dashboard",
      title: "Menu",
      type: "group",
      children: [
        {
          id: "dashboard",
          title: t("Dashboard"),
          type: "item",
          url: "/dashboard",
          icon: icons.DashboardOutlined,
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Dashboard"
          )?.read_access,
        },
        {
          id: "",
          title: "Notifications",
          type: "item",
          url: "/notifications",
          icon: icons.DashboardOutlined,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "",
          title: "Calendar",
          type: "item",
          url: "/calendar",
          icon: icons.DashboardOutlined,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "tickets",
          title: t("Tickets"),
          type: "item",
          url: "/tickets",
          icon: icons.ConfirmationNumberOutlined,
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Tickets"
          )?.read_access,
        },
        {
          id: "audit",
          title: t("Audit"),
          type: "item",
          url: "/audit",
          icon: icons.AuditOutlined,
          breadcrumbs: true,
          showMenu: true,
          // showMenu: access_list_data?.find(
          //   (item) => item.module_name === "Checklist"
          // )?.read_access,
        },
        {
          id: "checklist",
          title: t("Checklist"),
          type: "item",
          url: "/checklist",
          icon: icons.Checklist,
          breadcrumbs: true,
          showMenu: true,
          // showMenu: access_list_data?.find(
          //   (item) => item.module_name === "Checklist"
          // )?.read_access,
        },
        {
          id: "report",
          title: t("Report"),
          type: "item",
          url: "/report",
          icon: icons.FileTextOutlined,
          breadcrumbs: true,
          showMenu: true,
          // showMenu: access_list_data?.find(
          //   (item) => item.module_name === "Checklist"
          // )?.read_access,
        },
        {
          id: "Asset Management",
          title: t("Asset Management"),
          type: "collapse",
          icon: icons.CategoryOutlined,
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Asset Management"
          )?.read_access,
          children: [
            {
              id: "machine",
              title: t("Assets"),
              type: "item",
              url: "/asset",
              icon: icons.CategoryOutlined,
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Assets"
              )?.read_access,
            },
            {
              id: "analytics",
              title: t("Analytics"),
              type: "item",
              icon: icons.TimelineOutlined,
              url: "/analytics",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Analytics"
              )?.read_access,
            },
            {
              id: "asset-analytics2",
              title: t("Asset Analytics"),
              type: "item",
              icon: icons.TimelineOutlined,
              url: "/asset-analytic",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Asset Analytics"
              )?.read_access,
            },
          ],
        },

        {
          id: "spare_management",
          title: t("Spare Management"),
          type: "collapse",
          icon: icons.ViewQuiltOutlined,
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Spare Management"
          )?.read_access,
          children: [
            {
              id: "spare",
              title: t("Spares"),
              type: "item",
              url: "/spare-parts",
              icon: icons.ViewQuiltOutlined,
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Spares"
              )?.read_access,
            },
            {
              id: "inward",
              title: t("Inward"),
              type: "item",
              icon: icons.KeyboardDoubleArrowRight,
              url: "/spare-inward",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Inward"
              )?.read_access,
            },
            {
              id: "Outward",
              title: t("Outward"),
              type: "item",
              icon: icons.KeyboardDoubleArrowLeft,
              url: "/spare-outward",
              breadcrumbs: true,
              showMenu: true,
            },
            {
              id: "spare-analytics",
              title: t("Analytics"),
              type: "item",
              icon: icons.TimelineOutlined,
              url: "/spare-analytics",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Spare Analytics"
              )?.read_access,
            },
          ],
        },
        {
          id: "machine",
          title: "Add Machine",
          type: "item",
          url: "/add-asset",
          icon: icons.DashboardOutlined,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "report",
          title: "Report",
          type: "item",
          url: "/asset-report",
          icon: icons.Assignment,
          breadcrumbs: true,
          showMenu: false,
        },

        {
          id: "tickets",
          title: "Ticket Details",
          type: "item",
          url: "/ticket-details",
          icon: icons.ConfirmationNumberOutlined,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "tickets",
          title: "Create Ticket",
          type: "item",
          url: "/create-ticket",
          icon: icons.ConfirmationNumberOutlined,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "tickets",
          title: "Create Ticket",
          type: "item",
          url: "/maintenance-data",
          icon: icons.ConfirmationNumberOutlined,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "tickets",
          title: "Create Ticket",
          type: "item",
          url: "/break-down",
          icon: icons.ConfirmationNumberOutlined,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "",
          title: "User Profile",
          type: "item",
          url: "/user-profile",
          icon: icons.ConfirmationNumberOutlined,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "Visitor_Management",
          title: t("Visitor Management"),
          type: "collapse",
          icon: icons.ManageAccounts,
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Visitor Management"
          )?.read_access,
          children: [
            {
              id: "vendorConfig",
              title: t("Visitor Details"),
              type: "item",
              icon: icons.SupervisorAccount,
              url: "/visitor-details",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Visitor Details"
              )?.read_access,
            },
            {
              id: "vendorApproval",
              title: t("Visitor Approvals"),
              type: "item",
              icon: icons.HowToReg,
              url: "/approveVendor",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Visitor Approvals"
              )?.read_access,
            },
          ],
        },
        {
          id: "settings",
          title: t("Settings"),
          type: "collapse",
          icon: icons.Tune,
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Settings"
          )?.read_access,
          children: [
            {
              id: "ticketFlow",
              title: t("Ticket Work Flow"),
              type: "item",
              icon: icons.SchemaOutlined,
              url: "/ticket-work-flow",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Ticket Work Flow"
              )?.read_access,
            },
            {
              id: "userConfig",
              title: t("Users"),
              type: "item",
              icon: icons.PersonOutlineOutlined,
              url: "/user-configuration",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "User Configuration"
              )?.read_access,
            },
            {
              id: "AddLocation",
              title: t("Locations"),
              type: "item",
              icon: icons.FmdGoodOutlined,
              url: "/location-configuration",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Location Configuration"
              )?.read_access,
            },
            {
              id: "vendor-config",
              title: t("Vendors"),
              type: "item",
              icon: icons.FactoryOutlined,
              url: "/vendor-configuration",
              breadcrumbs: true,
              showMenu: true,
              // showMenu: access_list_data?.find(
              //   (item) => item.module_name === "Location Configuration"
              // )?.read_access,
            },
            // {
            //   id: "spare_config",
            //   title: "Spare Configuration",
            //   type: "item",
            //   icon: icons.FmdGood,
            //   url: "/spare-configuration",
            //   breadcrumbs: true,
            //   showMenu: true,
            // },
            // {
            //   id: "asset_config",
            //   title: "Asset Configuration",
            //   type: "item",
            //   icon: icons.FmdGood,
            //   url: "/asset-configuration",
            //   breadcrumbs: true,
            //   showMenu: true,
            // },
            {
              id: "designation-hierarchy",
              title: t("Designation Hierarchy"),
              type: "item",
              url: "/designation-hierarchy",
              icon: icons.ChromeOutlined,
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Designation Hierarchy"
              )?.read_access,
            },
            {
              id: "accessControl",
              title: t("Access Control"),
              type: "item",
              icon: icons.ControlOutlined,
              url: "/access-control",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Access Control"
              )?.read_access,
            },
            {
              id: "Comapny Details",
              title: t("Company Details"),
              type: "item",
              icon: icons.Groups,
              url: "/company-profile",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Company Details"
              )?.read_access,
            },
          ],
        },
      ],
    },

    {
      id: "support",
      title: t("Support"),
      type: "group",
      children: [
        {
          id: "documentation",
          title: t("Documentation"),
          type: "item",
          url: "https://nexgensis-1.gitbook.io/preventive-maintenance-web-application/",
          icon: icons.QuestionOutlined,
          external: true,
          target: true,
          showMenu: true,
        },
        // {
        //   id: "demo_flow",
        //   title: "Demo Ticket Work Flow",
        //   type: "item",
        //   icon: icons.SchemaOutlined,
        //   url: "/ticket-work-flow2",
        //   breadcrumbs: true,
        //   showMenu: true,
        // },
      ],
    },
  ].filter(Boolean);
}

export default Dashboard;
