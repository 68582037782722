import React, { useEffect, useState } from "react";

import MainCard from "../../../components/MainCard";
import {
  Typography,
  Stack,
  Button,
  MenuItem,
  OutlinedInput,
  Grid,
  Select,
  InputLabel,
  FormHelperText,
  IconButton,
  Chip,
  Tooltip,
} from "@mui/material";
import {
  add_breakdown_nature,
  add_breakdown_type,
  add_machine_breakdown_details,
  get_all_machineDetails,
  get_api_function,
  get_breakdown_nature,
  get_breakdown_type,
  get_flow_details,
  get_instruction_status,
  get_machine_breakdown_details,
  get_problem_solution,
  get_spare_details_by_machine,
  get_spare_part_inventory,
  post_api_function,
  save_machine_time_record,
  update_workflow_engin,
} from "utils/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Add } from "@mui/icons-material";
import { toast } from "react-toastify";
import { Modal, Table, Descriptions, Tabs, Badge } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Timer from "../StopWatch";
import CostInvolved from "../ticket-details/CostInvolved";
import ActionInTicket from "../ticket-details/ActionInTicket";
import TimeLineData from "../ticket-details/TimeLine";
import { FloatButton } from "antd";
import {
  QuestionCircleOutlined,
  DollarOutlined,
  MenuOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { Drawer, Select as AntSelect } from "antd";
import ChatBox from "../ticket-details/ChatBox";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

function AuditTicket() {
  const use_location = useLocation();
  const navigate = useNavigate();

  const ticket_id = use_location?.state?.ticket_id;
  const [transaction_id, set_transaction_id] = useState("");

  const [machine_details, set_machine_details] = useState([]);
  const [ticket_flow, set_ticket_flow] = useState([]);
  const [ticket_details, set_ticket_details] = useState(null);
  const [audit_ticket_details, set_audit_ticket_details] = useState();
  const [audit_data, set_audit_data] = useState();

  const [float_open, set_float_open] = useState(true);
  const [open, setOpen] = useState(false);
  const [open_drawer, set_open_drawer] = useState();

  //------------------------------------------------------------------------

  const [machine, setMachine] = useState();
  //------------------------------------------------------------------------

  const [instrutions_list, set_instructions_list] = useState([]);

  const success_set_instructions_list = (res) => {
    set_instructions_list(res.data.data);
  };

  useEffect(() => {
    get_instruction_status(success_set_instructions_list, ticket_id);
  }, [ticket_id]);

  const [clause_info_list, set_clause_info_list] = useState([]);
  const success_get_clause_info = (res) => {
    set_clause_info_list(res.data.data);
  };
  const [audit_finding_list, set_audit_finding_list] = useState([]);
  const success_get_audit_finding = (res) => {
    set_audit_finding_list(res.data.data);
  };
  const success_get_flow_details = (res) => {
    if (res.data.status === "success") {
      set_ticket_details(res?.data?.data[0].Main_details[0]);
      set_ticket_flow(res?.data?.data[0].Flow1);
      set_transaction_id(res?.data?.data[0].transaction_id);
    }
  };
  const success_get_machineDetails = (res) => {
    if (res.data.status === "success") set_machine_details(res?.data?.data[0]);
  };

  const success_get_audit_ticket_details = (res) => {
    if (res.data.status === "success") set_audit_ticket_details(res.data.data);
  };

  useEffect(() => {
    get_api_function(`/get_clause_info`, success_get_clause_info);
    get_api_function(`/get_audit_finding`, success_get_audit_finding);
    get_flow_details(success_get_flow_details, ticket_id);
    get_api_function(`/get_audit_ticket`, success_get_audit_ticket_details, {
      ticket_id: ticket_id,
    });
  }, []);

  const success_update_workflow_engin = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  //   Common modal handler
  const [add_index, set_add_index] = useState("");
  const [add_name, set_add_name] = useState("");
  const [description, set_description] = useState("");
  const [code, set_code] = useState("");
  const [add_open, set_add_open] = useState(false);

  //   Common modal submit hanlder
  const handle_submit_add_name = () => {
    post_api_function(
      add_index === "1"
        ? `/add_clause_info`
        : add_index === "2" && `/add_audit_finding`,
      success_config,
      {
        name: add_name,
        description,
        ...(add_index === "1"
          ? {
              code: code,
            }
          : {}),
      }
    );
  };

  const handle_modal_Cancel = () => {
    set_add_open(false);
    set_add_name("");
    set_code("");
    set_description("");
    set_add_index("");
  };

  const success_config = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      if (add_index === "1") {
        get_api_function(`/get_clause_info`, success_get_clause_info);
      }
      if (add_index === "2") {
        get_api_function(`/get_audit_finding`, success_get_audit_finding);
      }
      handle_modal_Cancel();
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  return (
    <Grid container>
      <FloatButton.Group
        trigger="click"
        type="primary"
        open={float_open}
        onClick={() => {
          set_float_open(!float_open);
        }}
        style={{
          right: open ? 524 : 24,
        }}
        icon={<MenuOutlined />}
      >
        {/* <Tooltip title="Work History">
          <FloatButton
            icon={<WorkHistoryOutlinedIcon fontSize="small" />}
            type="primary"
            onClick={() => {
              setOpen(!open);
              set_open_drawer("Work History");
            }}
          />
        </Tooltip>
        <Tooltip title="Time History">
          <FloatButton
            icon={<HistoryOutlined />}
            type="primary"
            onClick={() => {
              setOpen(!open);
              set_open_drawer("Time History");
            }}
          />
        </Tooltip> */}
        <Tooltip title="Cost Involved">
          <FloatButton
            icon={<DollarOutlined />}
            type="primary"
            onClick={() => {
              setOpen(!open);
              set_open_drawer("Cost Involved");
            }}
          />
        </Tooltip>
        {/* <Tooltip title="Support">
          <FloatButton
            icon={<QuestionCircleOutlined />}
            type="primary"
            onClick={() => {
              setOpen(!open);
              set_open_drawer("Support");
            }}
          />
        </Tooltip> */}
      </FloatButton.Group>
      <Grid item xs={12} lg={9}>
        <MainCard title="General Details">
          <Grid container spacing={3}>
            <Grid item lg={12}>
              <Descriptions
                column={3}
                size="middle"
                contentStyle={{}}
                labelStyle={{
                  fontWeight: "800",
                }}
                bordered
                items={[
                  {
                    key: 1,
                    label: "Audit ID",
                    children: audit_ticket_details?.audit_id,
                  },
                  {
                    key: 2,
                    label: "Department",
                    children: audit_ticket_details?.department,
                  },
                  {
                    key: 3,
                    label: "Location",
                    children: audit_ticket_details?.location,
                  },
                  {
                    key: 4,
                    label: "Auditor",
                    children: audit_ticket_details?.auditor,
                  },
                  {
                    key: 5,
                    label: "Auditee",
                    children: audit_ticket_details?.auditee,
                  },
                  {
                    key: 5,
                    label: "Relevant System	",
                    children: audit_ticket_details?.relevant_system,
                  },
                ]?.filter(Boolean)}
              />
            </Grid>
          </Grid>
        </MainCard>

        {
          <MainCard title="Actions">
            <Grid container spacing={3}>
              {(instrutions_list?.some(
                (i) => i.instruction_name === "Completed"
              ) ||
                ["Completed", "Closed", "Verified", "Resolved"]?.includes(
                  ticket_details?.ticket_status
                )) && (
                <Grid item lg={3}>
                  <AntSelect
                    showSearch
                    allowClear
                    id="asset"
                    size="large"
                    labelRender={(props) => {
                      return props?.label?.props?.children;
                    }}
                    placeholder="Select Asset"
                    optionFilterProp="children"
                    value={machine}
                    onChange={(value) => {
                      setMachine(value);
                      if (value) {
                        get_all_machineDetails(success_get_machineDetails, {
                          machine_id: value,
                        });
                        get_api_function(
                          `/get_audit_data`,
                          (res) => {
                            set_audit_data(res.data.data);
                          },
                          {
                            ticket_id: ticket_id,
                            machine_id: value,
                          }
                        );
                      }
                    }}
                    filterOption={(input, option) =>
                      (option?.label2 ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    options={audit_ticket_details?.machine_list?.map((item) => {
                      return {
                        value: item?.machine_id,
                        label: (
                          <Stack>
                            <Badge
                              color={
                                item?.audit_status === "Completed"
                                  ? "green"
                                  : "orange"
                              }
                              text={item?.name}
                            />
                          </Stack>
                        ),
                        label2: item?.name,
                      };
                    })}
                  />
                </Grid>
              )}
              {!machine && (
                <Grid item lg={12}>
                  <Table
                    pagination={false}
                    columns={[
                      {
                        title: "Asset",
                        dataIndex: "name",
                        key: "name",
                        align: "center",
                      },
                      {
                        title: "Audit Status",
                        dataIndex: "audit_status",
                        key: "audit_status",
                        align: "center",
                        render: (data) => (
                          <Badge
                            color={data === "Completed" ? "green" : "orange"}
                            text={data}
                          />
                        ),
                      },
                    ]}
                    dataSource={audit_ticket_details?.machine_list}
                    rowKey="machine_id"
                    bordered
                  />
                </Grid>
              )}
              {machine && (
                <Grid item lg={12}>
                  <Descriptions
                    column={3}
                    size="middle"
                    contentStyle={{}}
                    labelStyle={{
                      fontWeight: "800",
                    }}
                    bordered
                    items={[
                      {
                        key: 1,
                        label: "Asset Name",
                        children: machine_details?.name,
                      },
                      {
                        key: 4,
                        label: "Asset No",
                        children: machine_details?.assert_no,
                      },
                      {
                        key: 5,
                        label: "Installation Date",
                        children: machine_details?.installation_date?.slice(
                          0,
                          19
                        ),
                      },
                      {
                        key: 6,
                        label: "Line",
                        children: machine_details?.line_name,
                      },
                      {
                        key: 7,
                        label: "Location",
                        children: machine_details?.location,
                      },
                      {
                        key: 8,
                        label: "Tester Name",
                        children: machine_details?.tester_name,
                      },
                      {
                        key: 9,
                        label: "Model",
                        children: machine_details?.model,
                      },
                      {
                        key: 10,
                        label: "Asset Year",
                        children: machine_details?.machine_year,
                      },
                      {
                        key: 11,
                        label: "Software Details",
                        children: machine_details?.software_details,
                      },
                      {
                        key: 12,
                        label: "Asset Type",
                        children: machine_details?.machine_type,
                      },
                      {
                        key: 13,
                        label: "PM Frequency",
                        children: machine_details?.frequency,
                      },
                      {
                        key: 14,
                        label: "PM Date",
                        children: machine_details?.due_date?.slice(0, 19),
                      },
                    ]}
                  />
                </Grid>
              )}
              <Grid item lg={12}>
                {machine && (
                  <MainCard title="Observations">
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <Stack spacing={1}>
                          <InputLabel required id="Clause No">
                            Clause No.
                          </InputLabel>
                          <Stack direction="row" spacing={1}>
                            <AntSelect
                              showSearch
                              allowClear
                              id="Clause No"
                              size="large"
                              labelRender={(props) => {
                                return props?.label?.props?.children;
                              }}
                              placeholder="Select Clause Number"
                              optionFilterProp="children"
                              disabled={
                                audit_data?.audit_status === "Completed"
                              }
                              value={audit_data?.clause_id}
                              onChange={(value) => {
                                set_audit_data({
                                  ...audit_data,
                                  clause_id: value,
                                });
                                console.log(audit_data);
                              }}
                              filterOption={(input, option) =>
                                (option?.label2 ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{
                                width: "100%",
                                border: "0.5px solid #D9D9D9",
                                borderRadius: "3px",
                              }}
                              bordered={false}
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: "auto",
                              }}
                              options={clause_info_list?.map((item) => {
                                return {
                                  value: item?.id,
                                  label: (
                                    <div
                                      style={{
                                        maxWidth: "100%",
                                        wordWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      {`${item?.name} - ${item?.code}`}
                                    </div>
                                  ),
                                  label2: item?.name,
                                };
                              })}
                            />
                            <Tooltip title="Add Relevant System">
                              <IconButton
                                id="add-checklist_type_id"
                                onClick={() => {
                                  set_add_index("1");
                                  set_add_open(true);
                                }}
                              >
                                <Add color="primary" />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Stack spacing={1}>
                          <InputLabel required id="Type of Finding">
                            Type of Finding
                          </InputLabel>
                          <Stack direction="row" spacing={1}>
                            <AntSelect
                              showSearch
                              allowClear
                              id="Type of Finding"
                              size="large"
                              labelRender={(props) => {
                                return props?.label?.props?.children;
                              }}
                              placeholder="Select Type of Finding"
                              optionFilterProp="children"
                              disabled={
                                audit_data?.audit_status === "Completed"
                              }
                              value={audit_data?.type_of_finding}
                              onChange={(value) => {
                                set_audit_data({
                                  ...audit_data,
                                  type_of_finding: value,
                                });
                              }}
                              filterOption={(input, option) =>
                                (option?.label2 ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{
                                width: "100%",
                                border: "0.5px solid #D9D9D9",
                                borderRadius: "3px",
                              }}
                              bordered={false}
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: "auto",
                              }}
                              options={audit_finding_list?.map((item) => {
                                return {
                                  value: item?.id,
                                  label: (
                                    <div
                                      style={{
                                        maxWidth: "100%",
                                        wordWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      {item?.name}
                                    </div>
                                  ),
                                  label2: item?.name,
                                };
                              })}
                            />
                            <Tooltip title="Add Type of Finding">
                              <IconButton
                                id="add-checklist_type_id"
                                onClick={() => {
                                  set_add_index("2");
                                  set_add_open(true);
                                }}
                              >
                                <Add color="primary" />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Stack spacing={1}>
                          <InputLabel required id="address">
                            Observation Details
                          </InputLabel>
                          <OutlinedInput
                            minRows={3}
                            multiline
                            id="address"
                            type="text"
                            name="text"
                            placeholder="Enter Observation Details"
                            fullWidth
                            required
                            disabled={audit_data?.audit_status === "Completed"}
                            value={audit_data?.observation_details || ""}
                            onChange={(e) => {
                              set_audit_data({
                                ...audit_data,
                                observation_details: e.target.value,
                              });
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Stack spacing={1}>
                          <InputLabel required id="Correction Details">
                            Correction Details
                          </InputLabel>
                          <OutlinedInput
                            minRows={3}
                            multiline
                            id="address"
                            type="text"
                            name="text"
                            placeholder="Enter Correction Details"
                            fullWidth
                            required
                            disabled={audit_data?.audit_status === "Completed"}
                            value={audit_data?.correction_details || ""}
                            onChange={(e) => {
                              set_audit_data({
                                ...audit_data,
                                correction_details: e.target.value,
                              });
                            }}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <Stack spacing={1}>
                          <InputLabel required id="Root Cause of NC">
                            Root Cause of NC
                          </InputLabel>
                          <OutlinedInput
                            minRows={3}
                            multiline
                            id="Root Cause of NC"
                            type="text"
                            name="text"
                            placeholder="Enter Root Cause of NC"
                            fullWidth
                            required
                            disabled={audit_data?.audit_status === "Completed"}
                            value={audit_data?.root_cause_of_nc || ""}
                            onChange={(e) => {
                              set_audit_data({
                                ...audit_data,
                                root_cause_of_nc: e.target.value,
                              });
                            }}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <Stack spacing={1}>
                          <InputLabel required id="Corrective Action Taken">
                            Corrective Action Taken
                          </InputLabel>
                          <OutlinedInput
                            minRows={3}
                            multiline
                            id="Corrective Action Taken"
                            type="text"
                            name="text"
                            placeholder="Enter Corrective Action Taken"
                            fullWidth
                            required
                            disabled={audit_data?.audit_status === "Completed"}
                            value={audit_data?.corrective_action_taken || ""}
                            onChange={(e) => {
                              set_audit_data({
                                ...audit_data,
                                corrective_action_taken: e.target.value,
                              });
                            }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </MainCard>
                )}
              </Grid>
              {instrutions_list?.some(
                (i) => i.instruction_name === "Completed"
              ) && (
                <Grid item lg={12}>
                  <Stack
                    direction="row"
                    justifyContent="end"
                    spacing={2}
                    sx={{ my: 2 }}
                  >
                    {audit_ticket_details?.machine_list?.some(
                      (i) => i?.audit_status === "Pending"
                    ) ? (
                      audit_ticket_details?.machine_list?.find(
                        (i) => i?.machine_id === machine
                      )?.audit_status === "Pending" && (
                        <Button
                          variant="contained"
                          onClick={() => {
                            const success = async (res) => {
                              if (res.status === "success") {
                                toast.success(res.message);
                                set_audit_data();
                                setMachine();
                                get_api_function(
                                  `/get_audit_ticket`,
                                  success_get_audit_ticket_details,
                                  {
                                    ticket_id: ticket_id,
                                  }
                                );
                              } else if (res.status === "failed") {
                                toast.warning(res.message);
                              }
                            };
                            post_api_function(
                              "/add_audit_data",
                              success,
                              audit_data
                            );
                          }}
                        >
                          Save
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => {
                          const ast_formdata = new FormData();
                          ast_formdata.append("ticket_id", ticket_id);
                          ast_formdata.append(
                            "ticket_transaction_id",
                            transaction_id
                          );
                          ast_formdata.append(
                            "instruction_id",
                            instrutions_list?.find(
                              (i) => i.instruction_name === "Completed"
                            )?.instruction_id
                          );
                          update_workflow_engin(
                            ast_formdata,
                            success_update_workflow_engin
                          );
                        }}
                      >
                        Submit Audit
                      </Button>
                    )}
                  </Stack>
                </Grid>
              )}
            </Grid>
          </MainCard>
        }
      </Grid>
      <Grid container item xs={12} lg={3}>
        <MainCard
          sx={{
            width: "100%",
          }}
        >
          <Tabs
            tabBarExtraContent={
              <Button
                size="small"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            }
            items={[
              instrutions_list?.length > 0 &&
                !instrutions_list?.some(
                  (instruction) => instruction.instruction_name === "Completed"
                ) && {
                  key: "1",
                  label: "Action",
                  children: (
                    <ActionInTicket
                      transaction_id={transaction_id}
                      instrutions_list={instrutions_list}
                      machine_id={machine_details?.machine_id}
                    />
                  ),
                },
              {
                key: "2",
                label: "Timeline",
                children: <TimeLineData flow_data={ticket_flow} />,
              },
            ].filter(Boolean)}
          />
        </MainCard>
      </Grid>
      <Drawer
        title={open_drawer}
        placement="right"
        width={500}
        onClose={() => setOpen(false)}
        open={open}
        zIndex={2147}
      >
        {open_drawer === "Cost Involved" && (
          <CostInvolved history_ticket_id={ticket_id} />
        )}
        {open_drawer === "Support" && (
          <ChatBox
            params={{
              asset_type: machine_details?.machine_type,
              asset_model: machine_details?.model,
              asset_make: machine_details?.make,
              issue_type: ticket_details?.issue_type,
            }}
          />
        )}
      </Drawer>
      <Modal
        title={`Add ${
          add_index === "1" ? "Clause" : add_index === "2" && "Type of Finding"
        }`}
        open={add_open}
        okButtonProps={{
          disabled: add_name.length === 0,
        }}
        onOk={handle_submit_add_name}
        onCancel={handle_modal_Cancel}
        okText="Add"
        zIndex={1001}
      >
        <Grid container spacing={1} direction="row" alignItems="center">
          <Grid item xs={12} lg={12}>
            <Stack spacing={1}>
              <InputLabel required id="name">
                {`${
                  add_index === "1"
                    ? "Clause"
                    : add_index === "2" && "Type of Finding"
                }`}
              </InputLabel>
              <OutlinedInput
                id="name"
                type="text"
                name="text"
                placeholder={`Enter ${
                  add_index === "1"
                    ? "Clause"
                    : add_index === "2" && "Type of Finding"
                }`}
                fullWidth
                required
                value={add_name}
                onChange={(e) => {
                  set_add_name(e.target.value);
                }}
              />
            </Stack>
          </Grid>
          {add_index === "1" && (
            <Grid item xs={12} lg={12}>
              <Stack spacing={1}>
                <InputLabel required id="name">
                  {`${add_index === "1" && "Code"}`}
                </InputLabel>
                <OutlinedInput
                  id="name"
                  type="text"
                  name="text"
                  placeholder={`Enter ${add_index === "1" && "Code"}`}
                  fullWidth
                  required
                  value={code}
                  onChange={(e) => {
                    set_code(e.target.value);
                  }}
                />
              </Stack>
            </Grid>
          )}
          <Grid item xs={12} lg={12}>
            <Stack spacing={1}>
              <InputLabel id="name">Description </InputLabel>
              <OutlinedInput
                multiline
                minRows={2}
                id="name"
                type="text"
                name="text"
                placeholder={`Enter Description`}
                fullWidth
                required
                value={description}
                onChange={(e) => {
                  set_description(e.target.value);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
}

export default AuditTicket;
