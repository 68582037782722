import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import { Descriptions, Table } from "antd";
import { get_api_function } from "utils/api";
import { useLocation, useNavigate } from "react-router-dom";

function AuditReport() {
  const use_location = useLocation();
  const ticket_id = use_location?.state?.ticket_id;

  const [report_data, set_report_data] = useState();
  useEffect(() => {
    get_api_function(
      "/get_audit_report",
      (res) => {
        set_report_data(res.data.data);
      },
      {
        ticket_id: ticket_id,
      }
    );
  }, []);

  const expandedRowRender = (record) => (
    <Descriptions
      bordered
      size="small"
      column={4}
      layout="vertical"
      title={`Audit Data for ${record.name}`}
      labelStyle={{
        fontWeight: "800",
      }}
    >
      <Descriptions.Item label="Observation Details" style={{ width: "25%" }}>
        {record.audit_data.observation_details}
      </Descriptions.Item>
      <Descriptions.Item label="Correction Details" style={{ width: "25%" }}>
        {record.audit_data.correction_details}
      </Descriptions.Item>
      <Descriptions.Item label="Root Cause of NC" style={{ width: "25%" }}>
        {record.audit_data.root_cause_of_nc}
      </Descriptions.Item>
      <Descriptions.Item
        label="Corrective Action Taken"
        style={{ width: "25%" }}
      >
        {record.audit_data.corrective_action_taken}
      </Descriptions.Item>
    </Descriptions>
  );
  return report_data ? (
    <MainCard title="Audit Report" backbutton>
      <Descriptions bordered column={2}>
        <Descriptions.Item label="Audit ID">
          {report_data.audit_id}
        </Descriptions.Item>
        <Descriptions.Item label="Department">
          {report_data.department}
        </Descriptions.Item>
        <Descriptions.Item label="Location">
          {report_data.location}
        </Descriptions.Item>
        <Descriptions.Item label="Auditor">
          {report_data.auditor}
        </Descriptions.Item>
        <Descriptions.Item label="Auditee">
          {report_data.auditee}
        </Descriptions.Item>
        <Descriptions.Item label="Relevant System">
          {report_data.relevant_system}
        </Descriptions.Item>
      </Descriptions>

      <Table
        columns={[
          {
            title: "Machine Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Clause Number",
            key: "clause",
            render: (record) => record?.audit_data?.clause_no,
          },
          {
            title: "Type of Finding",
            key: "name",
            render: (record) => record?.audit_data?.type_of_finding_name,
          },
        ]}
        dataSource={report_data.machine_list}
        rowKey="machine_id"
        expandable={{
          expandedRowRender: expandedRowRender,
          rowExpandable: (record) => record.audit_status === "Completed",
        }}
        bordered
        style={{ marginTop: 16 }}
      />
    </MainCard>
  ) : (
    <MainCard title="Audit Report" backbutton>
      <h2>Report Not Available</h2>
    </MainCard>
  );
}

export default AuditReport;
