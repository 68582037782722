import { useRoutes } from "react-router-dom";
import React, { useEffect, useState } from "react";
// project import
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import { LOGIN_AUTH, LOGOUT_AUTH } from "store/reducers/auth";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const userModel = useSelector((state) => state.auth.userModel);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    checkLogin();
  }, [userModel.isLogin, location.pathname]);

  const [access_list_data, setaccess_list_data] = useState([]);
  const [refresh, set_refresh] = useState([]);

  useEffect(() => {
    console.log(userModel.isLogin, location.pathname);
    if (userModel.isLogin) {
      const storedData = localStorage.getItem("access_list_data");
      if (storedData) {
        setaccess_list_data(JSON.parse(storedData));
      } else {
        set_refresh(!refresh);
      }
    }
  }, [refresh, userModel.isLogin]);

  function checkLogin() {
    const token = localStorage.getItem("Token");
    const isMyTokenExpired = isExpired(token);


    if (!userModel.isLogin) {
      if (
        (location.pathname !== "/login" || location.pathname !== "/") &&
        isMyTokenExpired
      ) {
        LoginRoutes().children.some((item) => item.path === location.pathname)
          ? navigate(location.pathname)
          : navigate("/login");
        localStorage.setItem("lang", "en");
      }
    } else if (isMyTokenExpired && userModel.isLogin) {
      dispatch(LOGOUT_AUTH());
      navigate("/login");
    } else if (
      (location.pathname === "/login" || location.pathname === "/") &&
      !isMyTokenExpired
    ) {
      dispatch(LOGIN_AUTH());
      navigate("/tickets");
    } else {
      dispatch(LOGIN_AUTH());
    }
  }

  return useRoutes([
    LoginRoutes(),
    userModel.isLogin && MainRoutes(access_list_data),
  ]);
}
