import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  InputLabel,
  OutlinedInput,
  Grid,
  Divider,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import {
  Modal,
  Table,
  Select as Select1,
  TreeSelect,
  DatePicker,
  Descriptions,
} from "antd";
import {
  add_Machine_Attribute_Name,
  get_api_function,
  post_api_function,
} from "utils/api";
import { toast } from "react-toastify";
import { transformData } from "../ReusableFunctions";
import {
  Autorenew,
  Wysiwyg,
  DescriptionOutlined,
  Add,
} from "@mui/icons-material";
import { useNavigate } from "react-router";

function generateRandomCode() {
  function randomLetter() {
    return String.fromCharCode(65 + Math.floor(Math.random() * 26));
  }
  const randomLetters = Array.from({ length: 2 }, randomLetter).join("");
  const randomDigits = Math.floor(Math.random() * 10000)
    .toString()
    .padStart(4, "0");
  const generatedCode = `${randomLetters}-${randomDigits}`;
  return generatedCode;
}

function Audit({ show_add }) {
  const navigate = useNavigate();
  const checklist_columns = [
    {
      title: "Audit ID",
      dataIndex: "audit_id",
      key: "audit_id",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Auditor",
      dataIndex: "auditor",
      key: "auditor",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Auditee",
      dataIndex: "auditee",
      key: "auditee",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Relevant System",
      dataIndex: "relevant_system",
      key: "relevant_system",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Type of Audit",
      dataIndex: "type_of_audit",
      key: "type_of_audit",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Audit Date",
      dataIndex: "schedule_date",
      key: "schedule_date",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "frequency",
      align: "center",
      ellipsis: true,
      render: (_, record) => {
        return (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {record?.ticket_id && (
              <Tooltip title="View Details">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    navigate("/audit-ticket", {
                      state: {
                        ticket_id: record?.ticket_id,
                      },
                    });
                  }}
                >
                  <Wysiwyg color="primary" />
                </IconButton>
              </Tooltip>
            )}
            {record?.ticket_id && record?.audit_status === "Completed" && (
              <Tooltip title="View Report">
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => {
                    navigate("/audit-report", {
                      state: {
                        ticket_id: record?.ticket_id,
                      },
                    });
                  }}
                >
                  <DescriptionOutlined color="secondary" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ]?.filter(Boolean);
  const [relevant_system_id, set_relevant_system_id] = useState();
  const [audit_id, set_audit_id] = useState();
  const [department, setDepartment] = useState();
  const [location, setLocation] = useState();
  const [auditor, setAuditor] = useState();
  const [auditee, setAuditee] = useState();
  const [typeOfAudit, setTypeOfAudit] = useState();
  const [assets, setAssets] = useState([]);
  const [processes, setProcesses] = useState([]);
  const [dateTime, setDateTime] = useState();

  const resetFunction = () => {
    set_relevant_system_id();
    set_audit_id("");
    setDepartment();
    setLocation();
    setAuditor();
    setAuditee();
    setTypeOfAudit();
    setAssets([]);
    setProcesses([]);
    setDateTime();
  };
  //   success submit
  const success_submit = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      resetFunction();
      get_api_function(`/get_audit_schedule`, success_get_scheduled_data);
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  //   Common modal handler
  const [add_index, set_add_index] = useState("");
  const [add_name, set_add_name] = useState("");
  const [description, set_description] = useState("");
  const [add_open, set_add_open] = useState(false);

  //   Common modal submit hanlder
  const handle_submit_add_name = () => {
    post_api_function(
      add_index === "1" && `/add_relevant_system`,
      success_config,
      {
        name: add_name,
        description,
      }
    );
  };

  const handle_modal_Cancel = () => {
    set_add_open(false);
    set_add_name("");
    set_description("");
    set_add_index("");
  };

  const success_config = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      if (add_index === "1") {
        get_api_function(`/get_relevant_system`, success_get_relevant_system);
      }
      handle_modal_Cancel();
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  //------lists
  const [departments_list, set_departments_list] = useState([]);
  const success_get_departments = (res) => {
    set_departments_list(res.data.data);
  };
  const [locations_list, set_locations_list] = useState([]);
  const success_get_locations = (res) => {
    set_locations_list(res.data.data);
  };
  const [employees_list, set_employees_list] = useState([]);
  const success_get_employees = (res) => {
    set_employees_list(res.data.data);
  };
  const [machines_list, set_machines_list] = useState([]);
  const success_get_machines = (res) => {
    set_machines_list(res.data.data);
  };
  const [processes_list, set_processes_list] = useState([]);
  const success_get_processes = (res) => {
    set_processes_list(res.data.data);
  };
  const [relevant_system_list, set_relevant_system_list] = useState([]);
  const success_get_relevant_system = (res) => {
    set_relevant_system_list(res.data.data);
  };
  const [audit_type_list, set_audit_type_list] = useState([]);
  const success_get_audit_type = (res) => {
    set_audit_type_list(res.data.data);
  };
  const [scheduled_data, set_scheduled_data] = useState([]);
  const success_get_scheduled_data = (res) => {
    set_scheduled_data(res.data.data);
  };
  useEffect(() => {
    get_api_function(`pm/get_departments`, success_get_departments);
    get_api_function(`/pm/get_locations`, success_get_locations);
    get_api_function(`/pm/get_all_employee`, success_get_employees);
    get_api_function(`/pm/get_machine_list_details`, success_get_machines);
    get_api_function(`/pm/get_machine_list_details`, success_get_processes);
    get_api_function(`/get_relevant_system`, success_get_relevant_system);
    get_api_function(`/get_audit_type`, success_get_audit_type);
    get_api_function(`/get_audit_schedule`, success_get_scheduled_data);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {show_add && (
          <Grid item lg={12} container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Stack spacing={1}>
                <InputLabel required htmlFor="assetNumber">
                  Audit ID
                </InputLabel>
                <Stack direction="row" spacing={1}>
                  <OutlinedInput
                    id="assetNumber"
                    type="text"
                    name="assetNumber"
                    placeholder="Enter Audit ID"
                    fullWidth
                    required
                    value={audit_id}
                    onChange={(e) => {
                      set_audit_id(e.target.value);
                    }}
                  />
                  <Tooltip label="Generate Asset Number">
                    <IconButton
                      onClick={() => {
                        set_audit_id(generateRandomCode());
                      }}
                    >
                      <Autorenew color="primary" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack spacing={1}>
                <InputLabel htmlFor="department">Department</InputLabel>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Select1
                    showSearch
                    allowClear
                    id="department"
                    size="large"
                    labelRender={(props) => {
                      return props?.label?.props?.children;
                    }}
                    placeholder="Select Department"
                    optionFilterProp="children"
                    value={department}
                    onChange={(value) => {
                      setDepartment(value);
                    }}
                    filterOption={(input, option) =>
                      (option?.label2 ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    options={departments_list?.map((item) => {
                      return {
                        value: item?.department_id,
                        label: (
                          <div
                            style={{
                              maxWidth: "100%",
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item?.department_name}
                          </div>
                        ),
                        label2: item?.department_name,
                      };
                    })}
                  />
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack spacing={1}>
                <InputLabel htmlFor="Location">Location</InputLabel>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Select1
                    showSearch
                    allowClear
                    id="department"
                    size="large"
                    labelRender={(props) => {
                      return props?.label?.props?.children;
                    }}
                    placeholder="Select Location"
                    optionFilterProp="children"
                    value={location}
                    onChange={(value) => {
                      setLocation(value);
                    }}
                    filterOption={(input, option) =>
                      (option?.label2 ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    options={locations_list?.map((item) => {
                      return {
                        value: item?.location_id,
                        label: (
                          <div
                            style={{
                              maxWidth: "100%",
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item?.location}
                          </div>
                        ),
                        label2: item?.category,
                      };
                    })}
                  />
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack spacing={1}>
                <InputLabel htmlFor="date">Date</InputLabel>
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  value={dateTime}
                  onChange={(value) => setDateTime(value)}
                  needConfirm={false}
                  style={{
                    width: "100%",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "3px",
                  }}
                  size="large"
                />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack spacing={1}>
                <InputLabel htmlFor="Auditor">Auditor</InputLabel>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Select1
                    showSearch
                    allowClear
                    id="Auditor"
                    size="large"
                    labelRender={(props) => {
                      return props?.label?.props?.children;
                    }}
                    placeholder="Select Auditor"
                    optionFilterProp="children"
                    value={auditor}
                    onChange={(value) => {
                      setAuditor(value);
                    }}
                    filterOption={(input, option) =>
                      (option?.label2 ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    options={employees_list?.map((item) => {
                      return {
                        value: item?.employee_id,
                        label: (
                          <div
                            style={{
                              maxWidth: "100%",
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item?.full_name}
                          </div>
                        ),
                        label2: item?.full_name,
                      };
                    })}
                  />
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack spacing={1}>
                <InputLabel htmlFor="Auditee">Auditee</InputLabel>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Select1
                    showSearch
                    allowClear
                    id="Auditee"
                    size="large"
                    labelRender={(props) => {
                      return props?.label?.props?.children;
                    }}
                    placeholder="Select Auditee"
                    optionFilterProp="children"
                    value={auditee}
                    onChange={(value) => {
                      setAuditee(value);
                    }}
                    filterOption={(input, option) =>
                      (option?.label2 ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    options={employees_list?.map((item) => {
                      return {
                        value: item?.employee_id,
                        label: (
                          <div
                            style={{
                              maxWidth: "100%",
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item?.full_name}
                          </div>
                        ),
                        label2: item?.full_name,
                      };
                    })}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack spacing={1}>
                <InputLabel htmlFor="Relevant System">
                  Relevant System
                </InputLabel>
                <Stack direction="row" spacing={1}>
                  <Select1
                    showSearch
                    allowClear
                    id="Relevant System"
                    size="large"
                    labelRender={(props) => {
                      return props?.label?.props?.children;
                    }}
                    placeholder="Select Relevant System"
                    optionFilterProp="children"
                    value={relevant_system_id}
                    onChange={(value) => {
                      set_relevant_system_id(value);
                    }}
                    filterOption={(input, option) =>
                      (option?.label2 ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    options={relevant_system_list?.map((item) => {
                      return {
                        value: item?.id,
                        label: (
                          <div
                            style={{
                              maxWidth: "100%",
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item?.name}
                          </div>
                        ),
                        label2: item?.name,
                      };
                    })}
                  />
                  <Tooltip title="Add Relevant System">
                    <IconButton
                      id="add-checklist_type_id"
                      onClick={() => {
                        set_add_index("1");
                        set_add_open(true);
                      }}
                    >
                      <Add color="primary" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack spacing={1}>
                <InputLabel htmlFor="Type of Audit">Type of Audit</InputLabel>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Select1
                    showSearch
                    allowClear
                    id="Type of Audit"
                    size="large"
                    labelRender={(props) => {
                      return props?.label?.props?.children;
                    }}
                    placeholder="Select Type of Audit"
                    optionFilterProp="children"
                    value={typeOfAudit}
                    onChange={(value) => {
                      setTypeOfAudit(value);
                    }}
                    filterOption={(input, option) =>
                      (option?.label2 ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    options={audit_type_list?.map((item) => {
                      return {
                        value: item.name,
                        label: (
                          <div
                            style={{
                              maxWidth: "100%",
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item.name}
                          </div>
                        ),
                        label2: item.name,
                      };
                    })}
                  />
                </Stack>
              </Stack>
            </Grid>

            {typeOfAudit && (
              <Grid item lg={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="Assets">
                    {typeOfAudit === "Assets"
                      ? "Assets"
                      : typeOfAudit === "Processes" && "Processes"}
                  </InputLabel>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    {typeOfAudit === "Assets" ? (
                      <TreeSelect
                        showSearch
                        allowClear
                        style={{
                          width: "100%",
                          border: "0.5px solid #D9D9D9",
                          borderRadius: "3px",
                        }}
                        filterTreeNode={(search, item) => {
                          return (
                            item?.title
                              ?.toLowerCase()
                              ?.indexOf(search.toLowerCase()) >= 0
                          );
                        }}
                        treeNodeFilterProp="title"
                        value={assets}
                        variant="borderless"
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        placeholder="Select Assets"
                        size="large"
                        onChange={(value) => {
                          setAssets(value);
                          console.log(value);
                        }}
                        treeData={transformData(machines_list)}
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_ALL}
                        treeCheckStrictly={true}
                      />
                    ) : (
                      typeOfAudit === "Processes" && (
                        <Select1
                          showSearch
                          allowClear
                          id="Assets"
                          size="large"
                          labelRender={(props) => {
                            return props?.label?.props?.children;
                          }}
                          placeholder="Select Processes"
                          optionFilterProp="children"
                          value={processes}
                          onChange={(value) => {
                            setProcesses(value);
                          }}
                          filterOption={(input, option) =>
                            (option?.label2 ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{
                            width: "100%",
                            border: "0.5px solid #D9D9D9",
                            borderRadius: "3px",
                          }}
                          bordered={false}
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                          }}
                          options={[
                            { process_id: 1, process_name: "Casting" },
                            { process_id: 2, process_name: "Milling" },
                            {
                              process_id: 3,
                              process_name: "Injection Molding",
                            },
                            { process_id: 4, process_name: "Welding" },
                            { process_id: 5, process_name: "Electroplating" },
                          ]?.map((item) => {
                            return {
                              value: item?.process_id,
                              label: (
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    wordWrap: "break-word",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {item?.process_name}
                                </div>
                              ),
                              label2: item?.process_name,
                            };
                          })}
                        />
                      )
                    )}
                  </Stack>
                </Stack>
              </Grid>
            )}

            <Grid item lg={12} spacing={2}>
              <Stack
                direction="row"
                justifyContent="end"
                alignItems="center"
                spacing={2}
              >
                <Button variant="outlined" onClick={resetFunction}>
                  Reset
                </Button>
                <Button
                  variant="contained"
                  disabled={
                    !Boolean(department) ||
                    !Boolean(location) ||
                    !Boolean(dateTime) ||
                    !Boolean(auditor) ||
                    !Boolean(auditee) ||
                    !Boolean(
                      (typeOfAudit === "Assets" ? assets : processes)?.length
                    )
                  }
                  onClick={() => {
                    post_api_function("/add_audit_schedule", success_submit, {
                      audit_id: audit_id,
                      relevant_system_id: relevant_system_id,
                      department_id: department,
                      location_id: location,
                      auditor_id: auditor,
                      auditee_id: auditee,
                      type_of_audit: audit_type_list?.find(
                        (i) => i.name === typeOfAudit
                      )?.id,
                      schedule_dt: dateTime,
                      machine_list: assets?.map((i) => i.value),
                      processes: processes?.map((i) => i.value),
                    });
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
        {show_add && (
          <Grid item lg={12}>
            <Divider />
          </Grid>
        )}
        <Grid item lg={12}>
          <Table
            dataSource={scheduled_data}
            columns={checklist_columns}
            scroll={{ x: 500, y: 570 }}
            bordered
            pagination={false}
            size="small"
            rowKey="id"
            expandable={{
              expandedRowRender: (record) => (
                <Descriptions
                  bordered
                  size="small"
                  labelStyle={{
                    width: "10%",
                    fontWeight: "800",
                    textAlign: "center",
                  }}
                  items={[
                    {
                      key: "1",
                      label: "Assets",
                      children: record.machines?.map((i) => (
                        <Chip
                          label={i.name}
                          variant="outlined"
                          size="small"
                          sx={{ m: 0.5 }}
                        />
                      )),
                    },
                  ]}
                />
              ),
            }}
          />
        </Grid>

        <Modal
          title={`Add ${add_index === "1" && "Relevant System"}`}
          open={add_open}
          okButtonProps={{
            disabled: add_name.length === 0,
          }}
          onOk={handle_submit_add_name}
          onCancel={handle_modal_Cancel}
          okText="Add"
          zIndex={1001}
        >
          <Grid container spacing={1} direction="row" alignItems="center">
            <Grid item xs={12} lg={12}>
              <Stack spacing={1}>
                <InputLabel id="name">
                  {`${add_index === "1" && "Relevant System"}`}
                </InputLabel>
                <OutlinedInput
                  id="name"
                  type="text"
                  name="text"
                  placeholder={`Enter ${
                    add_index === "1" && "Relevant System"
                  }`}
                  fullWidth
                  required
                  value={add_name}
                  onChange={(e) => {
                    set_add_name(e.target.value);
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Stack spacing={1}>
                <InputLabel id="name">Description </InputLabel>
                <OutlinedInput
                  multiline
                  minRows={2}
                  id="name"
                  type="text"
                  name="text"
                  placeholder={`Enter Description`}
                  fullWidth
                  required
                  value={description}
                  onChange={(e) => {
                    set_description(e.target.value);
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
}

export default Audit;
