import { createSlice } from "@reduxjs/toolkit";

const workflowEdgesSlice = createSlice({
  name: "workflowEdges",
  initialState: [],
  reducers: {
    set_workflowEdges_list: (state, action) => {
      return action.payload;
    },
  },
});

export const { set_workflowEdges_list } = workflowEdgesSlice.actions;
export default workflowEdgesSlice.reducer;
