// menuItems.js
import Dashboard from "./dashboard";

const MenuItems = () => {
  const dashboard = Dashboard();

  const menuItems = {
    items: dashboard,
  };

  return menuItems;
};

export default MenuItems;
