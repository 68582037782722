// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const company_profile = localStorage.getItem("company_profile");
const company_profile_data = company_profile
  ? JSON.parse(company_profile)
  : {
      company_name: "",
      company_url: "",
      company_logo: "",
      theme_color: "",
      privacy_policy: "",
      terms_conditions: "",
      about_us: "",
    };

const initialState = company_profile_data;
// ==============================|| SLICE - MENU ||============================== //

const company_profile_slice = createSlice({
  name: "company_profile",
  initialState,
  reducers: {
    set_company_name: (state, action) => {
      state.company_name = action.payload;
    },
    set_company_url: (state, action) => {
      state.company_url = action.payload;
    },
    set_company_logo: (state, action) => {
      state.company_logo = action.payload;
    },
    set_theme_color: (state, action) => {
      state.theme_color = action.payload;
    },
    set_privacy_policy: (state, action) => {
      state.privacy_policy = action.payload;
    },
    set_terms_conditions: (state, action) => {
      state.terms_conditions = action.payload;
    },
    set_about_us: (state, action) => {
      state.about_us = action.payload;
    },
    set_company_profile: (state, action) => {
      state.company_name = action.payload.company_name;
      state.company_url = action.payload.company_url;
      state.company_logo = action.payload.company_logo;
      state.theme_color = action.payload.theme_color;
      state.privacy_policy = action.payload.privacy_policy;
      state.terms_conditions = action.payload.terms_conditions;
      state.about_us = action.payload.about_us;
    },
  },
});

export default company_profile_slice.reducer;

export const {
  set_company_logo,
  set_company_name,
  set_company_url,
  set_theme_color,
  set_privacy_policy,
  set_terms_conditions,
  set_about_us,
  set_company_profile,
} = company_profile_slice.actions;
