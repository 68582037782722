import MainCard from "components/MainCard";
import React from "react";
import { Stack, Button, Typography } from "@mui/material";

function ApproveAssign() {
  const id = window.location.hash?.split("/").pop();

  return (
    <MainCard sx={{ height: "100vh" }}>
      <Stack alignItems="center" spacing={3}>
        <Typography variant="h2">
          Are you sure you want to approve {id} ticket?
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button variant="contained">Approve</Button>
          <Button>Reject</Button>
        </Stack>
      </Stack>
    </MainCard>
  );
}

export default ApproveAssign;
