import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/material";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
// import Logo from 'components/Logo';
import Logo from "../../../../assets/images/CompanyLogo2.jpg";
// import Logo from "../../../../assets/images/CompanyLogo.png";
// import Logo from "../../../../assets/images/pioneer_soft.png";
// import Logo from "../../../../assets/images/my-image.png";
// import Logo from "../../../../assets/images/MicrosoftTeams-image1.png";
// import Logo from "../../../../assets/images/MicrosoftTeams-image1.png";
// import Lyncs from "../../../../assets/images/Fw_ Lyncs logos and color/Lyncs Transparent 2.png";
import { useSelector } from "react-redux";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const { company_logo } = useSelector((state) => state.company_profile);
  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 2 }}>
        <img
          style={{
            width: "100%",
            // "-webkit-filter":
            //   "drop-shadow(0.5px 0.5px 0 #fff) drop-shadow(-0.5px -0.5px 0 #fff)",
            // filter:
            //   "drop-shadow(0.5px 0.5px 0 #fff) drop-shadow(-0.5px -0.5px 0 #fff)",
          }}
          src={company_logo ? company_logo : 
            // Lyncs
            Logo
          }
          alt="Company Logo"
        />
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
