import { createSlice } from "@reduxjs/toolkit";

const workflowDataSlice = createSlice({
  name: "workflowData",
  initialState: [],
  reducers: {
    set_workflowData: (state, action) => {
      let temp = [...state];
      temp[parseInt(action.payload.id)] = {
        ...temp[parseInt(action.payload.id)],
        [action.payload.key]: action.payload.value,
        id: action.payload.id,
      };
      return temp;
    },
    set_workflowData_list: (state, action) => {
      return action.payload;
    },
    set_workflowData_on_node: (state, action) => {
      let temp = [...state];
      temp.push(action.payload);
      return temp;
    },
  },
});

export const {
  set_workflowData,
  set_workflowData_list,
  set_workflowData_on_node,
} = workflowDataSlice.actions;

export default workflowDataSlice.reducer;
