import { createSlice } from "@reduxjs/toolkit";

const asset_treeSlice = createSlice({
  name: "asset_tree",
  initialState: {
    current_asset_id: "",
    current_asset_name: "",
  },
  reducers: {
    set_asset_tree: (state, action) => {
      return action.payload;
    },
  },
});

export const { set_asset_tree } = asset_treeSlice.actions;
export default asset_treeSlice.reducer;
