import PropTypes from "prop-types";
import { useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";

// project import
import DrawerHeader from "./DrawerHeader";
import DrawerContent from "./DrawerContent";
import MiniDrawerStyled from "./MiniDrawerStyled";
import { drawerWidth } from "config";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open, handleDrawerToggle, window }) => {
  const { theme_color } = useSelector((state) => state.company_profile);
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  // responsive drawer container
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

  const customTheme = createTheme({
    palette: {
      text: {
        primary: "#fff",
        secondary: "#E2E2E2",
      },
      action: {
        disabled: "#fff",
      },
      divider: "#fff",
      background: {
        // paper: '#250630',
        // paper: "#323949",
        // paper: "#323949",
        // paper: theme_color ? theme_color : "#323949",
        paper: theme_color ? theme_color : "#1a1a1a",
        default: "#fff",
      },
    },
    typography: {
      htmlFontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
      h1: {
        fontWeight: 600,
        fontSize: "2.375rem",
        lineHeight: 1.21,
      },
      h2: {
        fontWeight: 600,
        fontSize: "1.875rem",
        lineHeight: 1.27,
      },
      h3: {
        fontWeight: 600,
        fontSize: "1.5rem",
        lineHeight: 1.33,
      },
      h4: {
        fontWeight: 600,
        fontSize: "1.25rem",
        lineHeight: 1.4,
      },
      h5: {
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: 1.5,
      },
      h6: {
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: 1.57,
      },
      caption: {
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: 1.66,
      },
      body1: {
        fontSize: "0.875rem",
        lineHeight: 1.57,
      },
      body2: {
        fontSize: "0.75rem",
        lineHeight: 1.66,
      },
      subtitle1: {
        fontSize: "0.875rem",
        fontWeight: 600,
        lineHeight: 1.57,
      },
      subtitle2: {
        fontSize: "0.75rem",
        fontWeight: 500,
        lineHeight: 1.66,
      },
      overline: {
        lineHeight: 1.66,
      },
      button: {
        textTransform: "capitalize",
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        component="nav"
        sx={{ flexShrink: { md: 0 }, zIndex: 1300 }}
        aria-label="mailbox folders"
      >
        {!matchDownMD ? (
          <MiniDrawerStyled variant="permanent" open={open}>
            {drawerHeader}
            {drawerContent}
          </MiniDrawerStyled>
        ) : (
          <Drawer
            container={container}
            variant="temporary"
            open={open}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                borderRight: `1px solid ${theme.palette.divider}`,
                backgroundImage: "none",
                boxShadow: "inherit",
              },
            }}
          >
            {open && drawerHeader}
            {open && drawerContent}
          </Drawer>
        )}
      </Box>
    </ThemeProvider>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default MainDrawer;
