// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./translations/en.json";
import translationFR from "./translations/mr.json";
import translationHI from "./translations/hi.json";
import translationKN from "./translations/kn.json";

const lang = localStorage.getItem("lang");

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    mr: {
      translation: translationFR,
    },
    hi: {
      translation: translationHI,
    },
    kn: {
      translation: translationKN,
    },
  },
  lng: lang,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
