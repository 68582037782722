// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const userModelString = localStorage.getItem("userModel");
const userModel = userModelString ? JSON.parse(userModelString) : { isLogin: false };

const initialState = {
  userModel: userModel,
};

// ==============================|| SLICE - MENU ||============================== //

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    LOGIN_AUTH: (state, action) => {
      state.userModel.isLogin = true;
      localStorage.setItem(
        "userModel",
        JSON.stringify({
          isLogin: true,
        })
      );
    },
    LOGOUT_AUTH: (state, action) => {
      state.userModel.isLogin = false;
      localStorage.setItem(
        "userModel",
        JSON.stringify({
          isLogin: false,
        })
      );
    },
  },
});

export default auth.reducer;

export const { LOGIN_AUTH, LOGOUT_AUTH } = auth.actions;
