import React, { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Stack,
  Tooltip,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

// project import
import Transitions from "components/@extended/Transitions";

// assets
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { get_notification } from "utils/api";
import { useNavigate } from "react-router";

import ThumbUpIcon from "@mui/icons-material/ThumbUp"; // -----> acknowledged
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
// -----> in progress
import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined";
//  ------> waiting for spare parts
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
// ------> complted
import AssignmentIndSharpIcon from "@mui/icons-material/AssignmentIndSharp";
// ----> assign
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { RightCircleFilled, UserSwitchOutlined } from "@ant-design/icons";
// -----> approved
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
//  ------> declined
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
// -------> closed
import TaskIcon from "@mui/icons-material/FlagCircleRounded";
// -------> resolved
import UndoIcon from "@mui/icons-material/Undo";
// ------> returned
import MoreIcon from "@mui/icons-material/More";
//  ------> others
import WorkIcon from "@mui/icons-material/Work";

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

const actionSX = {
  mt: "6px",
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",

  transform: "none",
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const icons = {
  "Sent for Approval": (
    <RightCircleFilled style={{ color: "#FF9800", fontSize: "20px" }} />
  ),
  "Sent for Assigning": (
    <AssignmentIndSharpIcon style={{ color: "#FBC02D", fontSize: "20px" }} />
  ),
  "Sent for working on ticket": <WorkIcon style={{ color: "#F57F17  " }} />,
  Acknowledged: <ThumbUpIcon style={{ color: "#008000" }} />,
  "Waiting For Spare Parts": (
    <HourglassBottomOutlinedIcon style={{ color: "#FF8F00" }} />
  ),
  Others: <MoreIcon style={{ color: "#FFC107" }} />,
  "In Progress": <WatchLaterOutlinedIcon style={{ color: "#FF8F00" }} />,
  Resolved: <TaskIcon style={{ color: "#006400" }} />,
  Closed: <ConfirmationNumberIcon style={{ color: "#333333" }} />,
  Returned: <UndoIcon style={{ color: "#008080" }} />,
  Rejected: (
    <CancelOutlinedIcon
      style={{
        color: "red",
      }}
    />
  ),
  "Sent for Reassigning": (
    <UserSwitchOutlined style={{ color: "#FFC107", fontSize: "20px" }} />
  ),
  Verified: <VerifiedOutlinedIcon style={{ color: "#008080" }} />,
  Completed: <CheckCircleIcon style={{ color: "#006400" }} />,
};

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = "grey.300";
  const iconBackColor = "grey.100";

  const [notifications_data, set_notifications_data] = useState([]);

  const success_get_notification = (res) => {
    set_notifications_data(res.data);
  };

  useEffect(() => {
    open &&
      get_notification(success_get_notification, {
        count: "",
      });
  }, [open]);

  const handleTimeLine = (item) => {
    if (item?.issue_type === "Maintenance") {
      navigate("/maintenance-data", {
        state: {
          ticket_id: item?.ticket_id,
          machine_id: item?.machine_id,
        },
      });
    } else if (item?.issue_type === "Breakdown" && item?.machine_id) {
      navigate("/break-down", {
        state: {
          ticket_id: item?.ticket_id,
          machine_id: item?.machine_id,
        },
      });
    } else {
      navigate("/ticket-details", {
        state: { ticket_id: item.ticket_unique_id?.split("-")[1] },
      });
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Tooltip title="Notifications">
        <IconButton
          disableRipple
          color="secondary"
          sx={{
            color: "text.primary",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
          }}
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={open ? "profile-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Badge
            // badgeContent={4}
            color="primary"
          >
            <NotificationsNoneOutlinedIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: "100%",
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    "& .MuiListItemButton-root": {
                      py: 0.5,
                      "& .MuiAvatar-root": avatarSX,
                      "& .MuiListItemSecondaryAction-root": {
                        ...actionSX,
                        position: "relative",
                      },
                    },
                  }}
                >
                  {notifications_data?.slice(0, 5)?.map((item) => {
                    return (
                      <React.Fragment>
                        <ListItemButton
                          onClick={() => {
                            handleTimeLine(item);
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                color: "success.main",
                                bgcolor: "success.lighter",
                              }}
                            >
                              {icons[item.ticket_status]}
                            </Avatar>
                          </ListItemAvatar>
                          <Stack>
                            <ListItemText
                              primary={
                                <Typography variant="h6">
                                  {item.notification}
                                </Typography>
                              }
                              secondary={item?.ticket_unique_id}
                            />
                          </Stack>
                          <ListItemSecondaryAction>
                            <Stack>
                              <Typography variant="caption" noWrap>
                                {item?.status_update_date?.slice(0, 10)}
                              </Typography>
                              <Typography variant="caption" noWrap>
                                {item?.status_update_date?.slice(11, 19)}
                              </Typography>
                            </Stack>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                      </React.Fragment>
                    );
                  })}

                  <Divider />
                  <ListItemButton
                    sx={{ textAlign: "center", py: `${12}px !important` }}
                    onClick={() => {
                      navigate("/notifications");
                      setOpen(false);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h6" color="primary">
                          View All
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
