import React, { useEffect, useState, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import {
  Stack,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { MessageList } from "react-chat-elements";
import { Input, Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Space, List, Card } from "antd";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { chat_bot_respone_view } from "utils/api";
const { Header, Content, Footer } = Layout;

const { TextArea } = Input;

const loadingComponent = () => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <ThreeDots
        visible={true}
        height="20"
        width="50"
        color="#546E7A"
        radius="5"
        ariaLabel="three-dots-loading"
      />
    </Stack>
  );
};

function ChatBox({ params }) {
  const bottomRef = useRef(null);

  const [messageList, set_messageList] = useState([
    {
      position: "left",
      type: "text",
      title: "Support",
      text: "Welcome! I'm here to assist you with any questions you have. Feel free to ask!",
      date: new Date(),
    },
  ]);
  const [new_text, set_new_text] = useState("");

  useEffect(() => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messageList]);

  const success_chat_bot_respone_view = (res) => {
    const updatedMessage = {
      position: "left",
      type: "text",
      title: "Support",
      text: res.data?.body?.result,
      date: new Date(),
    };
    set_messageList((prevMessageList) => {
      const lastMessageIndex = prevMessageList.length - 1;
      return [
        ...prevMessageList.slice(0, lastMessageIndex), // Keep previous messages unchanged
        updatedMessage, // Update the last message
      ];
    });
    set_new_text("");
  };

  const send_function = () => {
    set_messageList((prevItems) => [
      ...prevItems,
      {
        position: "right",
        type: "text",
        title: "Me",
        text: new_text,
        date: new Date(),
      },
      {
        title: "Support",
        position: "left",
        type: "text",
        text: loadingComponent(),
      },
    ]);

    chat_bot_respone_view(success_chat_bot_respone_view, {
      prompt: new_text,
      ...params,
    });
  };

  return (
    <Layout style={{ minHeight: "89vh", margin: 0 }}>
      <Content
        style={{
          width: "100%",
          height: "75vh",
          overflow: "auto",
        }}
      >
        <MessageList
          className="message-list"
          lockable={true}
          dataSource={messageList}
        />
        <div ref={bottomRef} />
      </Content>
      <Footer>
        <Stack direction="row" alignItems="end" spacing={1}>
          <TextArea
            placeholder="Message"
            allowClear
            value={new_text}
            onChange={(e) => {
              set_new_text(e.target.value);
            }}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={() => {
              if (new_text) {
                send_function();
              }
            }}
          />
        </Stack>
      </Footer>
    </Layout>
  );
}

export default ChatBox;
