import { createSlice } from "@reduxjs/toolkit";

const workflowNodesSlice = createSlice({
  name: "workflowNodes",
  initialState: [],
  reducers: {
    set_workflowNodes_list: (state, action) => {
      return action.payload;
    },
  },
});

export const {  set_workflowNodes_list } =
  workflowNodesSlice.actions;
export default workflowNodesSlice.reducer;
