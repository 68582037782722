function base64ToFile(base64String, fileName) {
  const binaryString = base64String && atob(base64String);
  const byteArray = new Uint8Array(binaryString?.length);
  for (let i = 0; i < binaryString?.length; i++) {
    byteArray[i] = binaryString?.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: "application/pdf" });
  const blobUrl = URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  anchor.href = blobUrl;
  anchor.download = fileName;

  anchor.click();

  URL.revokeObjectURL(blobUrl);
}
export function handleDownloadbase64ToFile(base64String, fileName) {
  base64ToFile(base64String, fileName);
}