import React from "react";
import { useState } from "react";
import { Grid, Stack, Tooltip, IconButton, InputLabel } from "@mui/material";
import { Modal, Tabs, Select as Select1 } from "antd";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";

import MainCard from "components/MainCard";
import Audit from "./Audit";
import CloseIcon from "@mui/icons-material/Close";
import MaintenaceIcon from "../../../assets/images/icons/MaintenanceIcon.png";
import { get_api_function, post_api_function } from "utils/api";
import { toast } from "react-toastify";

function AuditForms() {
  const [tabValue, set_tabValue] = useState("1");
  const [show_add, set_show_add] = useState(false);
  const [schedule_open, set_schedule_open] = useState(false);
  const [schedule_date, set_schedule_date] = useState(dayjs().add(1, "day"));
  const [check_list_id, set_check_list_id] = useState("");
  const operations = (
    <Stack p={0} alignItems="center" direction="row" spacing={{ lg: 2, xs: 0 }}>
      <Tooltip title="Schedule Audit">
        <IconButton
          edge={false}
          onClick={() => {
            set_show_add(!show_add);
          }}
        >
          {show_add ? (
            <CloseIcon
              color="primary"
              style={{
                fontSize: "30px",
              }}
            />
          ) : (
            <AddIcon
              color="primary"
              style={{
                fontSize: "30px",
              }}
            />
          )}
        </IconButton>
      </Tooltip>
    </Stack>
  );
  // );

  const [checklist_list, set_checklist_list] = useState([]);
  const success_custom_checklist_list = (res) => {
    set_checklist_list(res.data.data);
  };
  const success_custom_checklist = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      set_check_list_id("");
      set_schedule_date(dayjs().add(1, "day"));
      set_schedule_open(false);
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  return (
    <MainCard>
      <Tabs
        defaultActiveKey="1"
        activeKey={tabValue}
        tabBarExtraContent={operations}
        onChange={(tab) => {
          set_tabValue(tab);
        }}
        items={[
          {
            label: "Schedule Audit",
            key: "1",
            children: <Audit show_add={show_add} />,
          },
        ]}
      />
      <Modal
        title={"Schedule maintenance"}
        open={schedule_open}
        onCancel={() => {
          set_schedule_open(false);
        }}
        okButtonProps={{
          disabled: !(Boolean(schedule_date) && Boolean(check_list_id)),
        }}
        onOk={() => {
          post_api_function(
            "/pm/schedule_ticket_for_custom_checklist",
            success_custom_checklist,
            {
              checklist_id: check_list_id,
              schedule_date: schedule_date
                ?.utc()
                .add(5, "hour")
                .add(30, "minute"),
            }
          );
        }}
        okText="Add"
        zIndex={1001}
      >
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Select1
              showSearch
              allowClear
              size="large"
              placeholder="Select Checklist"
              optionFilterProp="children"
              value={check_list_id}
              onChange={(value) => {
                set_check_list_id(value);
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{
                width: "100%",
                border: "0.5px solid #D9D9D9",
                borderRadius: "3px",
              }}
              bordered={false}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              options={checklist_list?.map((item) => {
                return {
                  value: item?.checklist_id,
                  label: item?.checklist_name,
                };
              })}
            />
          </Grid>

          <Grid item lg={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="spare_part_name" required>
                Maintenance Date-Time
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={schedule_date}
                  onChange={(value) => {
                    set_schedule_date(value);
                  }}
                  minDate={dayjs().add(1, "day")}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
        </Grid>
      </Modal>
    </MainCard>
  );
}
export default AuditForms;
